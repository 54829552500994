<!-- eslint-disable -->
<template>
  <div ref="timeLine" class="reset-timeline">
    <hr class="dropdown-divider" />
    <app-timeline>
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item>
        <div
          class="
            d-flex
            flex-sm-row flex-column flex-wrap
            justify-content-between
            mb-1 mb-sm-0
          "
        >
          <h6>12 Invoices have been paid</h6>
          <small class="text-muted">12 min ago</small>
        </div>
        <p>Invoices have been paid to the company.</p>
        <p>
          <b-img
            :src="require('@/assets/images/icons/pdf.png')"
            height="auto"
            width="20"
            class="mr-1"
          />
          <span class="align-bottom">invoice.pdf</span>
        </p>
      </app-timeline-item>

      <app-timeline-item variant="secondary">
        <div
          class="
            d-flex
            flex-sm-row flex-column flex-wrap
            justify-content-between
            mb-1 mb-sm-0
          "
        >
          <h6>Client Meeting</h6>
          <small class="text-muted">45 min ago</small>
        </div>
        <p>Project meeting with john @10:15am.</p>
        <b-media>
          <template #aside>
            <b-avatar :src="require('@/assets/images/avatars/12-small.png')" />
          </template>
          <h6>John Doe (Client)</h6>
          <p>CEO of Infibeam</p>
        </b-media>
      </app-timeline-item>

      <!-- INTERVIEW SCHEDULE -->
      <app-timeline-item variant="warning">
        <div
          class="
            d-flex
            flex-sm-row flex-column flex-wrap
            justify-content-between
            mb-1 mb-sm-0
          "
        >
          <h6>Interview Schedule</h6>
          <small class="text-muted">03:00 PM</small>
        </div>
        <p>Have to interview Katy Turner for the developer job.</p>
        <div
          class="
            d-flex
            flex-sm-row flex-column
            justify-content-between
            align-items-start
          "
        >
          <b-media>
            <template #aside>
              <b-avatar :src="require('@/assets/images/avatars/1-small.png')" />
            </template>
            <h6>Katy Turner</h6>
            <span class="text-muted">Javascript Developer</span>
          </b-media>
          <div>
            <feather-icon icon="MessageSquareIcon" class="mr-1" />
            <feather-icon icon="PhoneCallIcon" />
          </div>
        </div>
      </app-timeline-item>
    </app-timeline>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { BImg, BAvatar, BMedia } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BImg,
    BMedia,
    BAvatar,
  },
};
</script>

<style>
.reset-timeline {
  transform: translateY(-18px);
}
</style>
